














import { useUser } from '~/modules/customer/composables/useUser';
import { onMounted, ref } from '@nuxtjs/composition-api';
import { useHidePriceStore } from '@/components/TopBar/hidePriceStore';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const {
    load: loadUser
  } = useUser();
  const hidePriceStore = useHidePriceStore();
  const isVisible = ref<boolean>(hidePriceStore.hidePrice);
  const hidePrice = () => {
    if (localStorage.getItem('isVisible') !== null) {
      hidePriceStore.hidePrice = !hidePriceStore.hidePrice;
      isVisible.value = hidePriceStore.hidePrice;
      localStorage.setItem('isVisible', hidePriceStore.hidePrice);
    } else {
      hidePriceStore.hidePrice = true;
      isVisible.value = true;
      localStorage.setItem('isVisible', hidePriceStore.hidePrice);
    }
  };
  onMounted(async () => {
    if (localStorage.getItem('isVisible') === 'false') {
      hidePriceStore.hidePrice = false;
    } else {
      localStorage.setItem('isVisible', true);
      hidePriceStore.hidePrice = true;
    }
    isVisible.value = hidePriceStore.hidePrice;
  });
  return {
    isVisible,
    hidePrice
  };
};
export default __sfc_main;
